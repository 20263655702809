import useSWR, { mutate } from "swr";
import { useMemo } from "react";

import axiosInstance, { endpoints, fetcher } from "src/utils/axios";
import { IMasterController, ITemplate } from "src/types/settings";
import { ERROR_MSG } from "src/config-global";

export interface ChangePasswordData {
    role_name: "transfer" | "void" | "rollback";
    current_password: number;
    new_password: number;
    confirm_password: number;
  }
  
//   export interface ApiResponse {
//     success: boolean;
//     message: string;
//     // Add other fields as necessary based on your API response structure
//   }


const URL = endpoints.settings.master_controller;
const TEMPLATESURL = endpoints.settings.templates;
const PASSCODEURL = endpoints.settings.change_passcode;

export function useGetMainMasterController() {
    const { data, isLoading, error, isValidating } = useSWR(URL,fetcher);
    const memoizedValue = useMemo(
        () => ({
            masterControls: (data?.data as IMasterController[]) || [],
            masterControlsLoading: isLoading,
            masterControlsError: error,
            masterControlsValidating: isValidating,
            masterControlsEmpty: !isLoading && !data?.data,
        }),
        [data?.data ,error, isLoading, isValidating]
    );
    
    return memoizedValue;
}


export async function updateMainMasterController(data:any) {
    try {
        await axiosInstance.put(endpoints.settings.updateStatus,data);
        mutate(URL)
    } catch (error) {
        if(error && error.status_message) throw error.status_message;
        else throw ERROR_MSG;
    }
}


export function useGetTemplates() {
    const { data, isLoading, error, isValidating } = useSWR(TEMPLATESURL,fetcher);
    const memoizedValue = useMemo(
        () => ({
            templates: (data?.data as ITemplate[]) || [],
            templatesCount:data?.data?.length,
            templatesLoading: isLoading,
            templatesError: error,
            templatesValidating: isValidating,
            templatesEmpty: !isLoading && !data?.data,
        }),
        [data?.data ,error, isLoading, isValidating]
    );
    
    return memoizedValue;
}
export const updateChangePasscode = async (data: ChangePasswordData) => {
    try {
    const response = await axiosInstance.put(PASSCODEURL,data);
    return response
    }catch (error) {
        if(error && error.status_message) throw error.status_message;
        else throw ERROR_MSG;
        
    }
    // if (!response.response_error ===true) {
    //   const errorData = await response;
    //   throw new Error(errorData.message || 'Failed to change password');
    // }
  
    // const responseData: ApiResponse = await response.json();
    // return responseData;
  };