import axios from "axios";
import { IPermission, ISectionPermission } from "src/types/roles";


export function generatePassword(length:number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export function getSectionPermissionValues(permission_name:string,permissions:ISectionPermission[] | null | undefined){
  if(!permissions || permissions.length === 0 ) return null;
  // console.log(permissions);
  return permissions.find((permission)=>permission.menu_name?.toLowerCase() === permission_name.toLowerCase());
}

export function getPermissionValues(permission_name:string,permissions:IPermission[]| null | undefined){
  if(!permissions || permissions.length === 0 ) return { full_access:true,restrict_access:false,view_only:false} as IPermission;
  // console.log(permissions);
  return permissions.find((permission)=>permission.permission_name?.toLowerCase() === permission_name.toLowerCase());
}

export const validatePermissions = (permissions:any[]|undefined) =>{
  if(permissions){
      permissions.forEach((menu:ISectionPermission)=>{
          if(!menu.restrict_access){
              if(menu.permissions?.length>0){
                  menu.permissions.forEach((permission)=>{
                      if(!permission.full_access && !permission.restrict_access && !permission.view_only){
                          throw new Error("Please state every access");
                      }
                  })
              }
              validatePermissions(menu.childrens);
          }
      })
  }
}

export const convertToQueryString = (obj: any): string =>{
  // Map each key-value pair to a 'key=value' format
  const parts: string[] = [];

  Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (Array.isArray(value)) {
          // Handle array values
          value.forEach(item => {
              parts.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`);
          });
      } else {
          // Convert boolean values to lowercase strings
          const formattedValue = typeof value === 'boolean' ? String(value).toLowerCase() : value;
          parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(formattedValue)}`);
      }
  });


  // Join all parts by the '&' character to form the query string
  return parts.join('&');
}
export function convertToQueryStrings(params: Record<string, string>): string {
  const queryString = Object.keys(params)
      .filter(key => params[key])
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
  return queryString;
}
export const handleResponse = (res:{data:any,response_error:boolean,status_code:number,status_message:string})=>{
  try {
    if(res && !res.response_error) return res.data;
    return null
  } catch (error) {
    return null
  }
}
export const handleResponseSet = (res:{data:any,response_error:boolean,status_code:number,status_message:string})=>{
  try {
    if(res && !res.response_error) return res;
    return null
  } catch (error) {
    return null
  }
}


export const getIP = async () => {
  const res = await axios.get(`https://api-bdc.net/data/ip-geolocation-full?key=bdc_655c0adebc9040d891bac02d6ea38dfd`);
  // console.log(res.data);
  if(res && res.data && res.data.ip) return res.data.ip;
  return null
  // return "192.168.0.1"
};