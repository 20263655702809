import { Helmet } from "react-helmet-async";
import PasscodeChangeView from "src/sections/settings/passcode-change/view/view";


export default function PasscodeChange(){
    return(
        <>
            <Helmet>
                <title>Change Passcode</title>
            </Helmet>
            <PasscodeChangeView />
        </>
    )
}