import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
// utils
import axiosInstance, { fetcher, endpoints } from 'src/utils/axios';

// types
import { ICategory } from 'src/types/category';
import { ERROR_MSG } from 'src/config-global';
import { limits } from 'src/types/common';


const URL = endpoints.category.list;

export function useGetCategories() {
   
    const { data, isLoading, error, isValidating } = useSWR(URL,fetcher);

    const memoizedValue = useMemo(
        () => ({
            categories: (data?.data as ICategory[]) || [],
            categoriesLoading: isLoading,
            categoriesError: error,
            categoriesValidating: isValidating,
            categoriesEmpty: !isLoading && !data?.data?.length,
        }),
        [data?.data ,error, isLoading, isValidating]
        );
    
        return memoizedValue;
}

export async function updateCategoriesLimits(menu_limits:limits[],category_id:string){
    try {
        const data = {menu_limits};
        await axiosInstance.put(endpoints.category.set_limit(category_id),data);
        if(category_id)
        {
          mutate(URL)
        }
    } catch (error) {
        if(error && error.status_message) throw error.status_message;
        else throw ERROR_MSG;
    }
}