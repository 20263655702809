import { useEffect } from 'react';
// hooks
import { useAppDispatch } from 'src/hooks/use-redux';
// api
import { useGetCategories } from 'src/api/category';
import { useGetSportsByCategory } from 'src/api/sport';
// redux
import { updateCategories, updateSports } from 'src/redux/reducers/category-slice';
// types
import { ICategory } from 'src/types/category';

export default function CategoryProvider(){
      // get all categories 
  const { categories } = useGetCategories();
  const sportCategory = categories.find((c:ICategory)=>c.game_type === "sport");
  const {sports} = useGetSportsByCategory(sportCategory?.menu_id || "");
  const dispatch = useAppDispatch()


  useEffect(()=>{
    dispatch(updateCategories(categories))
  },[categories,dispatch]);

  useEffect(()=>{
    dispatch(updateSports(sports))
  },[sports,dispatch]);


    return(<></>)
}