import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICurrency } from "src/types/currency";


type ICurrencySlice = {
    currencies : ICurrency[],
}

const initialState: ICurrencySlice = {
    currencies:[]
};

export const currencySlice = createSlice({
    name:"currencies",
    initialState,
    reducers:{
        updateCurrencies:(state,action:PayloadAction<ICurrency[]>) =>{
            state.currencies = action.payload;
        },
    }
})


export const { updateCurrencies } = currencySlice.actions;

export default currencySlice.reducer;