
import { useEffect } from "react";


import { useGetCurrencies } from "src/api/currency"
import { useAppDispatch } from "src/hooks/use-redux";

import { updateCurrencies } from "../reducers/currency-slice";

export default function CurrencyProvider() {
    
    const dispatch = useAppDispatch()

    const { currencies } = useGetCurrencies();

    useEffect(()=>{
        dispatch(updateCurrencies(currencies))
      },[currencies,dispatch]);

    return(<></>)
}