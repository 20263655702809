// ChangePassword.tsx

import React from 'react';
import { useForm, SubmitHandler} from 'react-hook-form';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { Container, Box, TextField, Button, Typography, Snackbar, FormControl, InputLabel, Select, MenuItem,SnackbarContent,FormHelperText, InputAdornment,IconButton } from '@mui/material';
import { updateChangePasscode,ChangePasswordData } from 'src/api/settings';
import Iconify from 'src/components/iconify';


interface FormData {
  role_name: "transfer" | "void" | "rollback";
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const PasscodeChangeView: React.FC = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm<FormData>();
  const confirmPassword = watch('newPassword');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false
  });
  const handleClickShowPassword = (field: keyof typeof showPassword) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  };


  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const { role_name, currentPassword, newPassword, confirmNewPassword } = data;
    // const token = 'YOUR_JWT_TOKEN_HERE'; // Replace with your actual token

    try {
      const res = await updateChangePasscode({ 
        role_name, 
        current_password: Number(currentPassword), 
        new_password: Number(newPassword), 
        confirm_password:Number(confirmNewPassword)
      });
      setSnackbarMessage(res.data.status_message);
      setIsSuccess(true);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error during password change:', error);
      setSnackbarMessage(error || 'An unexpected error occurred');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <Container maxWidth="sm">
   
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 8 }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Change Password
        </Typography>
        <FormControl fullWidth margin="normal">
            
          <InputLabel id="role">Role</InputLabel>
          <Select
            label="role"
            defaultValue=""
            {...register('role_name', { required: 'Role is required' })}
            // error={!!errors.role_name}
          >
            <MenuItem value="transfer">transfer</MenuItem>
            <MenuItem value="void">void</MenuItem>
            <MenuItem value="rollback">rollback</MenuItem>
          </Select>
          {errors.role_name &&<FormHelperText>{errors.role_name?.message}</FormHelperText>}
        </FormControl>
        <TextField
          label="Current Password"
          type={showPassword.currentPassword ? 'number' : 'password'}
          fullWidth
          margin="normal"
          {...register('currentPassword', { required: 'Current password is required' })}
          error={!!errors.currentPassword}
          helperText={errors.currentPassword?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword('currentPassword')}
                  edge="end"
                >
                  {showPassword.currentPassword ?  <Iconify icon="ion:eye-off"  sx={{color:'black'}} />: <Iconify icon="ion:eye"  sx={{color:'black'}} />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
    
        <TextField
          label="New Password"
          type={showPassword.newPassword ? 'number' : 'password'}
          fullWidth
          margin="normal"
          {...register('newPassword', { required: 'New password is required', minLength: { value: 6, message: 'Password must be at least 6 characters' } })}
          error={!!errors.newPassword}
          helperText={errors.newPassword?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword('newPassword')}
                  edge="end"
                >
                  {showPassword.newPassword ? <Iconify icon="ion:eye-off"  sx={{color:'black'}} />: <Iconify icon="ion:eye"  sx={{color:'black'}} />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <TextField
          label="Confirm New Password"
          type={showPassword.confirmNewPassword ? 'number' : 'password'}
          fullWidth
          margin="normal"
          {...register('confirmNewPassword', {
            required: 'Please confirm your new password',
            validate: (value) => value === confirmPassword || 'Passwords do not match'
          })}
          error={!!errors.confirmNewPassword}
          helperText={errors.confirmNewPassword?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword('confirmNewPassword')}
                  edge="end"
                >
                  {showPassword.confirmNewPassword ? <Iconify icon="ion:eye-off"  sx={{color:'black'}} />: <Iconify icon="ion:eye"  sx={{color:'black'}} />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
        >
          Change Password
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          message={snackbarMessage}
          style={{ backgroundColor: isSuccess ? 'green' : 'red' }}
        />
        </Snackbar>
    </Container>
  );
}

export default PasscodeChangeView;
