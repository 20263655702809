import { useCallback, useMemo, useState } from 'react';

import { useAppSelector } from 'src/hooks/use-redux';
import { useParams } from 'src/routes/hooks';
import { BreadcrumbContext } from './breadcrumb-context';


type Props = {
    children: React.ReactNode;
  };

export function BreadcrumbProvider({children}:Props){
    const { sports } = useAppSelector((state) => state.categories)


    

    const [dynmicName,setDynamicName] = useState<string>("");

    const getDynamicSportName = useCallback((sportId:string) =>{
        const sport  = sports.find((s)=>s.provider_game_id === sportId);
        console.log("fdjnkfl",sports,sportId)
        if(sport) return sport.game_name;
        return "";
    },[sports])

    const memoizedValue = useMemo(()=>({
        dynmicName,
        setDynamicName,
        getDynamicSportName
    }),[dynmicName,setDynamicName,getDynamicSportName])

    return(
        <BreadcrumbContext.Provider value={memoizedValue}>{children}</BreadcrumbContext.Provider>
    )

}