import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
// import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify';
import { IS_PROD } from 'src/config-global';
import { useAuthContext } from 'src/auth/hooks';
import { ISectionPermission } from 'src/types/roles';
import { getSectionPermissionValues } from 'src/utils/custom-functions';
import { SECTIONS, SUBSECTIONS } from 'src/_mock/_sections';
// ----------------------------------------------------------------------
const icon = (name: string) => (
  // <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  <Iconify icon={name} />
  // <Iconify icon="solar:football-bold" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);
const ICONS = {
  analytics: icon('fluent:arrow-growth-24-regular'),
  bets: icon('solar:list-check-bold-duotone'),
  user: icon('solar:user-bold'),
  site_settings: icon('solar:settings-bold'),
  site_admins: icon('solar:shield-user-bold-duotone'),
  site_logs: icon('solar:book-2-bold'),
  site_developer: icon('solar:devices-bold'),
  site_management: icon('solar:laptop-outline'),
  casino_cms: icon('solar:gamepad-minimalistic-bold'),
  site_transfer: icon('solar:hand-money-line-duotone'),
  site_transferstatement: icon('solar:hand-money-line-duotone'),
  roles: icon('solar:users-group-rounded-bold'),
  notification: icon('solar:notification-unread-lines-bold-duotone'),
  sports: icon('solar:cup-star-bold'),
  casino: icon('solar:gamepad-minimalistic-outline'),
  surveillance:icon('solar:card-search-bold-duotone'),
};
// ----------------------------------------------------------------------
export function useNavData() {
  const { user } = useAuthContext();
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'overview',
        items: [
          { title: 'Analytics', path: paths.dashboard.analytics, icon: ICONS.analytics,roles:IS_PROD ? ['user'] :["admin"],restrict_access:true },
          { title: 'Sports', path: paths.dashboard.sports.root, icon: ICONS.sports,
            restrict_access:getSectionPermissionValues(SECTIONS.SPORTS,user?.permissions)?.restrict_access
          },
          { title: 'Bets', path: paths.dashboard.bets.root, icon: ICONS.bets,roles:IS_PROD ? ['user','admin'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.BETS,user?.permissions)?.restrict_access,
            children:[
              { title: 'Event Bets', path: paths.dashboard.bets.root,restrict_access:getSectionPermissionValues(SUBSECTIONS.BETS.EVENT_BETS,user?.permissions)?.restrict_access, },
              { title: 'Casino Bets', path: paths.dashboard.bets.casino,restrict_access:getSectionPermissionValues(SUBSECTIONS.BETS.CASINO_BETS,user?.permissions)?.restrict_access, },
              { title: 'Previous Bets', path: paths.dashboard.bets.previous,restrict_access:getSectionPermissionValues(SUBSECTIONS.BETS.PREVIOUS_BETS,user?.permissions)?.restrict_access, },
            ]
          },
          { title: 'Users', path: paths.dashboard.users.root, icon: ICONS.user,roles:IS_PROD ? ['user'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.USERS,user?.permissions)?.restrict_access,
            children:[
              { title: 'CA Users', path: paths.dashboard.users.CA_users.root,roles:IS_PROD ? ['user'] :["admin"],
                restrict_access:getSectionPermissionValues(SUBSECTIONS.USERS.CA_USERS,user?.permissions)?.restrict_access },
              { title: 'Clients', path: paths.dashboard.users.clients.root,
                restrict_access:getSectionPermissionValues(SUBSECTIONS.USERS.Clients,user?.permissions)?.restrict_access },
              { title: 'Super Admins', path: paths.dashboard.users.super_admins.root,roles:IS_PROD ? ['user'] :["admin"],
              restrict_access:getSectionPermissionValues(SUBSECTIONS.USERS.SUPERADMINS,user?.permissions)?.restrict_access },
            ]
          },
          {
            title:'All Logs', path: paths.dashboard.all_logs.root,icon: ICONS.site_logs,roles:IS_PROD ? ['user','admin'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.SETTINGS,user?.permissions)?.restrict_access,
            children:[
              {title:"Session Logs",path:paths.dashboard.all_logs.session_logs,roles:IS_PROD ? ['user'] :["admin"],
              restrict_access:getSectionPermissionValues(SUBSECTIONS.ALL_LOGS.SESSION_LOGS,user?.permissions)?.restrict_access},
              {title:"Multiple IP Logs",path:paths.dashboard.all_logs.multiple_ip_logs,roles:IS_PROD ? ['user'] :["admin"],
              restrict_access:getSectionPermissionValues(SUBSECTIONS.ALL_LOGS.SESSION_LOGS,user?.permissions)?.restrict_access},
              {title:"Error Logs",path:paths.dashboard.all_logs.error_logs,roles:IS_PROD ? ['user'] :["admin"],
              restrict_access:getSectionPermissionValues(SUBSECTIONS.ALL_LOGS.SESSION_LOGS,user?.permissions)?.restrict_access}
            ]
          },
          { title: 'Tranfers', path: paths.dashboard.transfer, icon: ICONS.site_transfer,roles:IS_PROD ? ['user','admin'] :["admin"],
          restrict_access:getSectionPermissionValues(SECTIONS.TRANSFER,user?.permissions)?.restrict_access,
          children:[
            { title: 'Transfers', path: paths.dashboard.transfer,restrict_access:getSectionPermissionValues(SUBSECTIONS.TRANSFER.TRANSFER,user?.permissions)?.restrict_access, },
            { title: 'Transfer Statement', path: paths.dashboard.transferstatement,restrict_access:getSectionPermissionValues(SUBSECTIONS.TRANSFER.TRANSFERSTATEMENT,user?.permissions)?.restrict_access, },
            
          ]
        },
        //   { title: 'Transfers', path: paths.dashboard.transfer, icon: ICONS.site_transfer,roles:IS_PROD ? ['user','admin'] :["admin"],
        //     restrict_access:getSectionPermissionValues(SECTIONS.TRANSFER,user?.permissions)?.restrict_access
        //   },
        //   { title: 'Transfer Statement', path: paths.dashboard.transferstatement, icon: ICONS.site_transferstatement,roles:IS_PROD ? ['user','admin'] :["admin"],
        //   restrict_access:getSectionPermissionValues(SECTIONS.TRANSFERSTATEMENT,user?.permissions)?.restrict_access
        // },
          { title: 'Settings', path: paths.dashboard.settings.root, icon: ICONS.site_settings,roles:IS_PROD ? ['user','admin'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.SETTINGS,user?.permissions)?.restrict_access,
            children:[
              { title: 'Template Customization', path: paths.dashboard.settings.templates,roles:IS_PROD ? ['user'] :["admin"],
                restrict_access:getSectionPermissionValues(SUBSECTIONS.SETTINGS.MASTER_CONTROLLER,user?.permissions)?.restrict_access },
              { title: 'Master Controller', path: paths.dashboard.settings.master_controller,roles:IS_PROD ? ['user'] :["admin"],
                restrict_access:getSectionPermissionValues(SUBSECTIONS.SETTINGS.MASTER_CONTROLLER,user?.permissions)?.restrict_access },
              { title: 'Change Passcode', path: paths.dashboard.settings.change_passcode,roles:IS_PROD ? ['user'] :["admin"],
                restrict_access:getSectionPermissionValues(SUBSECTIONS.SETTINGS.MASTER_CONTROLLER,user?.permissions)?.restrict_access },
            ]
          },
          { title: 'Developer', path: paths.dashboard.developer.root, icon: ICONS.site_developer,roles:IS_PROD ? ['user','admin'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.DEVELOPER,user?.permissions)?.restrict_access,
            children:[
              { title: 'Maintenance Mode', path: paths.dashboard.developer.maintenance,roles:IS_PROD ? ['user'] :["admin"],
                restrict_access:getSectionPermissionValues(SUBSECTIONS.DEVELOPER.MAINTENANCE,user?.permissions)?.restrict_access },
              { title: 'Dev Tools', path: paths.dashboard.developer.dev_tools,roles:IS_PROD ? ['user'] :["admin"],
                restrict_access:getSectionPermissionValues(SUBSECTIONS.DEVELOPER.DEV_TOOLS,user?.permissions)?.restrict_access },
            ]
          },
          { title: 'Site Management', path: paths.dashboard.sites.list, icon: ICONS.site_management,roles:IS_PROD ? ['user','admin'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.SITE_CMS,user?.permissions)?.restrict_access
          },
          { title: 'Surveillance', path: paths.dashboard.surveillance, icon: ICONS.surveillance,
            roles:IS_PROD ? ['user','admin'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.SURVEILLANCE,user?.permissions)?.restrict_access
          },
          { title: 'Casino Games', path: paths.dashboard.casino_cms, icon: ICONS.casino_cms,roles:IS_PROD ? ['user','admin'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.CASINO_GAMES,user?.permissions)?.restrict_access
          },
          { title: 'Notifications', path: paths.dashboard.notification, icon: ICONS.notification,roles:IS_PROD ? ['user'] :["admin"],
            restrict_access:getSectionPermissionValues(SECTIONS.NOTIFICATIONS,user?.permissions)?.restrict_access
          },
          // { title: 'Maintenance Mode', path: paths.dashboard.maintenance, icon: ICONS.site_admins,roles:IS_PROD ? ['user'] :["admin"],restrict_access:true}
        ],
      },
      // MANAGEMENT
      // ----------------------------------------------------------------------
      // {
      //   subheader: 'events',
      //   items: [
      //     { title: 'Sports', path: paths.dashboard.sports.root, icon: ICONS.sports,
      //       restrict_access:getSectionPermissionValues(SECTIONS.SPORTS,user?.permissions)?.restrict_access
      //     },
      //     // { title: 'Casino', path: paths.dashboard.casino, icon: ICONS.casino },
      //   ],
      // },
    ],
    [user]
  );
  return data;
}